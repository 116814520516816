import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { AUTH_MODULE } from "@/core/config/role.config";
import { RbacService } from "@/core/services/rbac.service";

export default {
  components: {},
  computed: {
    allowedNavigation() {
      return this.navigation.where(x => this.allowedLinks(x.links).length > 0);
    }
  },
  methods: {
    allowedLinks(links) {
      const allowedLinks = [];
      links.forEach(link => {
        if (RbacService.isAllowed(link.roles)) allowedLinks.push(link);
      });
      return allowedLinks;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Group" },
      { title: "Navigation Menu Group" }
    ]);

    if (process.env.VUE_APP_OTC_FLAG === "true") {
      this.navigation.push({
        header: "Sales",
        links: [
          {
            name: "Sharepoint XLSB Quotes Upload",
            to: "sales/upload-quote-xlsb",
            icon: "mdi-microsoft-sharepoint",
            roles: AUTH_MODULE.GROUP_SALES_SHAREPOINT_XLSB_UPLOAD
          },
          {
            name: "Dynamics CE CSV Quotes Upload",
            to: "sales/upload-quote-csv",
            icon: "mdi-microsoft-dynamics-365",
            roles: AUTH_MODULE.GROUP_SALES_DYNAMICS_CSV_UPLOAD
          }
        ],
        flexmd: 4,
        flexsm: 6
      });
    }
  },
  data: () => ({
    navigation: [
      {
        header: "Tools",
        links: [
          {
            name: "New Relic Query Language",
            to: "tools/nrql",
            icon: "mdi-database-search",
            roles: AUTH_MODULE.GROUP_NRQL
          }
        ],
        flexmd: 4,
        flexsm: 6
      },
      {
        header: "SMS Tool",
        links: [
          {
            name: "SMS",
            to: "sms-tool/sms",
            icon: "mdi-cellphone-message",
            roles: AUTH_MODULE.GROUP_SMS
          }
        ],
        flexmd: 4,
        flexsm: 6
      },
      {
        header: "Finance",
        links: [
          {
            name: "Customer Pricing Rules Upload",
            to: "finance/customer-pricing",
            icon: "mdi-cloud-upload",
            roles: AUTH_MODULE.GROUP_FINANCE_PRICING_UPLOAD
          },
          {
            name: "Sentia Standard Pricing Rules Upload",
            to: "finance/sentia-standard-pricing",
            icon: "mdi-cloud-upload",
            roles: AUTH_MODULE.GROUP_FINANCE_PRICING_UPLOAD
          }
        ],
        flexmd: 4,
        flexsm: 6
      },
      {
        header: "Reporting",
        links: [
          {
            name: "Service Management Reports",
            to: "reporting/service-management-report",
            icon: "mdi-file",
            roles: AUTH_MODULE.GROUP_SERVICE_MANAGEMENT_REPORTS
          },
          {
            name: "My Insights",
            to: "reporting/my-insights",
            icon: "mdi-shimmer",
            roles: AUTH_MODULE.GROUP_MY_INSIGHTS
          },
          {
            name: "License Report",
            to: "reporting/license-report",
            icon: "mdi-license",
            roles: AUTH_MODULE.GROUP_MY_INSIGHTS
          },
          {
            name: "Premium Report",
            to: "reporting/premium-report",
            icon: "mdi-license",
            roles: AUTH_MODULE.GROUP_MY_INSIGHTS
          }
        ],
        flexmd: 4,
        flexsm: 6
      },
      {
        header: "Managed Services Connector",
        links: [
          {
            name: "Tags",
            to: "managed-services-connector/tags",
            icon: "mdi-tag-multiple",
            roles: AUTH_MODULE.GROUP_MSC
          },
          {
            name: "Customers",
            to: "managed-services-connector/customers",
            icon: "mdi-account-lock",
            roles: AUTH_MODULE.GROUP_MSC
          },
          {
            name: "Blacklisted Resources",
            to: "managed-services-connector/blacklisted-resources",
            icon: "mdi-semantic-web",
            roles: AUTH_MODULE.GROUP_MSC
          },
          {
            name: "Data Quality Issues",
            to: "managed-services-connector/data-quality-issues",
            icon: "mdi-database-alert",
            roles: AUTH_MODULE.GROUP_MSC
          }
        ],
        flexmd: 4,
        flexsm: 6
      }
    ]
  })
};
